<script setup>
import { ref as dbRef, getDatabase, onValue } from "firebase/database";

const placeholder = "Loading";
const api_from = useState("api_from", () => placeholder);
const api_to = useState("api_to", () => 0);
const sse_from = useState("sse_from", () => placeholder);
const sse_to = useState("sse_to", () => 0);
const whk_from = useState("whk_from", () => placeholder);
const whk_to = useState("whk_to", () => 0);

if (process.client) {
  let api_interval = null;
  let sse_interval = null;
  let whk_interval = null;

  const db = dbRef(getDatabase(), "metrics");

  const update_metrics = (snapshot) => {
    const { api, sse, webhook } = snapshot.val();
    api_to.value = Object.values(api).reduce((sum, v) => sum + v, 0);
    sse_to.value = Object.values(sse).reduce((sum, v) => sum + v, 0);
    whk_to.value = Object.values(webhook).reduce((sum, v) => sum + v, 0);
    if (api_from.value == placeholder) api_from.value = api_to.value;
    if (sse_from.value == placeholder) sse_from.value = sse_to.value;
    if (whk_from.value == placeholder) whk_from.value = whk_to.value;
  };

  const animate_metric = (interval, from, to) => {
    if (interval) clearInterval(interval);
    let d = to.value - from.value;
    if (d <= 0) return false;

    let z = Math.ceil(d / 30); // max 10fps for 3 seconds
    let x = Math.round(3000 / (d / z)); // calculate fps

    return setInterval(() => {
      if (from.value < to.value) {
        from.value += z;
      }
    }, x);
  };

  // Client Side
  const stop = onValue(db, (snapshot) => {
    update_metrics(snapshot);
    api_interval = animate_metric(api_interval, api_from, api_to);
    sse_interval = animate_metric(sse_interval, sse_from, sse_to);
    whk_interval = animate_metric(whk_interval, whk_from, whk_to);
  });
  onBeforeUnmount(() => {
    stop();
    clearInterval(api_interval);
    clearInterval(sse_interval);
    clearInterval(whk_interval);
  });
}
</script>

<template>
  <div class="text-center">
    <p class="text-gray-500 dark:text-gray-400 text-xl">API Requests</p>
    <p class="value text-6xl text-primary-500 dark:text-primary-400 font-bold">
      {{ api_from?.toLocaleString() }}
    </p>
    <br />
    <p class="text-gray-500 dark:text-gray-400 text-xl">Server Sent Events</p>
    <p class="value text-primary-500 dark:text-primary-400 text-6xl font-bold">
      {{ sse_from?.toLocaleString() }}
    </p>
    <br />
    <p class="text-gray-500 dark:text-gray-400 text-xl">Webhooks</p>
    <p class="value text-primary-500 dark:text-primary-400 text-6xl font-bold">
      {{ whk_from?.toLocaleString() }}
    </p>
  </div>
</template>

<style scoped>
.value {
  font-family: Roboto, sans-serif;
}
</style>
