<script setup lang="ts">
const colorMode = useColorMode();
const haImage = computed(() =>
  colorMode.value === "dark" ? "/images/ha-dark.png" : "/images/ha-light.png"
);
</script>

<template>
  <UCard class="ha" :ui="{ body: { padding: '' } }">
    <ClientOnly>
      <a :href="haImage" target="_blank">
        <img
          :src="haImage"
          alt="A Home Assistant dashboard showing a variety of the data and controls available from a Tesla vehicle using Teslemetry"
        />
      </a>
    </ClientOnly>
  </UCard>
</template>

<style scoped>
.ha {
  background-color: #fafafa;
  aspect-ratio: 1630/1192;
  overflow: hidden;
}

.dark {
  .ha {
    background-color: #111111;
  }
}
</style>
